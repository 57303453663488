import Link from 'next/link';

import { AppLinks } from 'utils/links';

function Footer() {
  return (
    <div className="Footer">
      <div className="Container">
        <ul className="Footer__menu">
          <li className="Footer__menuItem">
            <Link href={AppLinks.terms} passHref legacyBehavior>
              <a className={'Footer__menuItemLink'}>Terms and Conditions</a>
            </Link>
          </li>
          <li className="Footer__menuItem">
            <Link href={AppLinks.privacy} passHref legacyBehavior>
              <a className={'Footer__menuItemLink'}>Privacy Policy</a>
            </Link>
          </li>
        </ul>
        <a
          href="https://forcandies.com"
          target="_blank"
          rel="noopener noreferrer"
          className="Footer__copyright"
        >
          <span>&copy; {new Date().getFullYear()}</span> Made{' '}
          <strong>forCandies</strong>
        </a>
      </div>
    </div>
  );
}

export default Footer;
